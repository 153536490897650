import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import styles from "@styles/components/Form/select.module.scss";
import useFormError from "@components/Form/hooks/useFormError";
import Select from "@components/Select";

/**
 * FormSelect component for rendering a select input.
 *
 * @typedef {object} ExtraFormSelectProps
 * @property {string} label - The label text for the select input.
 * @property {Function} additionalOnChangeCb - Additional onChange callback (optional).
 */

/**
 * @typedef {ExtraFormSelectProps & import("@components/Select").CustomSelectProps} FormSelectProps
 */

/**
 * FormSelect component for rendering a select input.
 * @param {FormSelectProps} props - Props for the FormSelect component.
 * @returns {JSX.Element} The rendered component.
 */
const FormSelect = ({ label, additionalOnChangeCb = () => {}, ...rest }) => {
	const { control } = useFormContext();
	const { markupError } = useFormError(rest.name);

	return (
		<div className={styles.customSelectWrapper}>
			<label>
				{label}

				<Controller
					name={rest.name}
					control={control}
					render={({ field }) => (
						<Select
							{...field}
							{...rest}
							onChange={(option) => {
								field.onChange(option);
								additionalOnChangeCb(option);
							}}
						/>
					)}
				/>
			</label>

			{markupError}
		</div>
	);
};

export default FormSelect;
