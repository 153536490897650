import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Button, Spinner } from "reactstrap";
import styles from "./index.module.scss";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { dispatcher } from "@redux/actions/dispatchers";
import Pagination from "@views/LeadsList/components/Pagination";
import PaginationFooter from "@components/PaginationFooter";
import moment from "moment";
import { errorToast } from "@helpers/toastFunctions";
import getLastLeadId from "@helpers/getLastLeadId";
import DatePicker from "@components/DatePicker";
import getIdTipo from "@helpers/user/getIdTipo";
import { formatDateWithoutHour } from "@helpers/formatDate";
import { useDebouncedEffect } from "@helpers/useDebouncedEffect";
import { HiDocumentAdd, HiDocumentText } from "react-icons/hi";
import Loading from "@views/Loading/Loading";
import { UserContext } from "@contexts/UserContext";
import { environment } from "@config/environment.config";
import { useTranslation } from "react-i18next";
import Mainlayout from "@layouts/MainLayout";
import { FiFilter } from "react-icons/fi";
import ModalFilters from "@components/ModalFilters";
import RolType from "@constants/roles";
import Checkbox from "@components/Checkbox";
import Select from "@components/Select";
import {
  getFilterActionsDone,
  getFilterActualState,
  getFilterAgent,
  getFilterAllDealersihps,
  getFilterCommercialBrands,
  getFilterContact,
  getFilterDealerships,
  getFilterGestor,
  getFilterInvolvedEmployee,
  getFilterKmsFrom,
  getFilterKmsUntil,
  getFilterOrigin,
  getFilterProvincesLiteral,
  getFilterVehicleBrand,
  getFilterYear
} from "@services/Filtros/filtros";

import MultiSelectChipsList from "@components/MultiSelectChipsList";

const LeadsList = ({ storedFilters, dispatchLeadsFilters }) => {
  const { user } = useContext(UserContext);
  const { local } = useContext(UserContext);
  const { rol } = useContext(UserContext);
  const { t } = useTranslation("leadsCompra");

  const urlParams = new URLSearchParams(window.location.search);
  const section = urlParams.get("section");

  const [leads, setLeads] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterLocalesTodos, setFilterLocalesTodos] = useState(false)

  const [misLocales, setMisLocales] = useState([0]);
  const [filterLocales, setFilterLocales] = useState([]);
  const [filterEstados, setFilterEstados] = useState([]);
  const [filterFormaContacto, setFilterFormaContacto] = useState([]);
  const [filterProcedencia, setFilterProcedencia] = useState([]);
  const [filterMarca, setFilterMarca] = useState([]);
  const [filterFreeSearch, setfilterFreeSearch] = useState(null);
  const [freeSearchValue, setFreeSearchValue] = useState("");
  const [filterMarcasComerciales, setFilterMarcasComerciales] = useState([]);
  const [filterProvincia, setFilterProvincia] = useState([]);
  const [filterAnno, setFilterAnno] = useState([]);
  const [filterKmDesde, setFilterKmDesde] = useState([]);
  const [filterKmHasta, setFilterKmHasta] = useState([]);
  const [filterGestor, setFilterGestor] = useState([]);
  const [filterEmpleadoImplicado, setFilterEmpleadoImplicado] = useState([]);
  const [filterAccionesRealizadas, setFilterAccionesRealizadas] = useState([]);
  const [filterDateStart, setFilterDateStart] = useState(
    moment().subtract(4, "months").valueOf()
  );
  const [filterDateEnd, setFilterDateEnd] = useState();
  const [filterCitaStart, setFilterCitaStart] = useState();
  const [filterCitaEnd, setFilterCitaEnd] = useState();
  const [filterAccionStart, setFilterAccionStart] = useState();
  const [filterAccionEnd, setFilterAccionEnd] = useState();
  const [changeType, setChangeType] = useState(null);
  const [changeData, setChangeData] = useState([]);
  const [changeState, setChangeState] = useState([]);
  const [changeManager, setChangeManager] = useState([]);
  const [changeAll, setChangeAll] = useState(true);
  const [hasFilterChanged, setHasFilterChanged] = useState(false);
  const [initialFetch, setInitialFetch] = useState(false);
  const [hasLimitPageChanged, setHasLimitPageChanged] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);

  const [filterList, setFilterList] = useState([]);

  const filtersMemo = useMemo(() => {
    return {
      filterLocalesTodos,
      filterLocales,
      filterEstados,
      filterMarcasComerciales,
      filterFormaContacto,
      filterProcedencia,
      filterMarca,
      filterProvincia,
      filterAnno,
      filterKmDesde,
      filterKmHasta,
      filterGestor,
      filterEmpleadoImplicado,
      filterAccionesRealizadas,
      filterDateStart: filterDateStart || "",
      filterDateEnd: filterDateEnd
        ? moment(filterDateEnd).set({ hour: 23, minute: 59, second: 59 }).valueOf()
        : "",
      filterCitaStart: filterCitaStart
        ? moment(filterCitaStart).set({ hour: 0, minute: 0 })
        : "",
      filterCitaEnd: filterCitaEnd
        ? moment(filterCitaEnd).set({ hour: 23, minute: 59, second: 59 })
        : "",
      filterAccionStart: filterAccionStart
        ? moment(filterAccionStart).set({ hour: 0, minute: 0 })
        : "",
      filterAccionEnd: filterAccionEnd
        ? moment(filterAccionEnd).set({ hour: 23, minute: 59, second: 59 })
        : "",
    };
  }, [
    filterLocales,
    filterEstados,
    filterMarcasComerciales,
    filterFormaContacto,
    filterProcedencia,
    filterMarca,
    filterProvincia,
    filterAnno,
    filterKmDesde,
    filterKmHasta,
    filterGestor,
    filterEmpleadoImplicado,
    filterAccionesRealizadas,
    filterDateStart,
    filterDateEnd,
    filterCitaStart,
    filterCitaEnd,
    filterAccionStart,
    filterAccionEnd,
    filterLocalesTodos
  ]);

  const [userType, setUserType] = useState();

  const fetchPayloadMemo = useMemo(() => {
    return {
      page,
      limit,
      filterFreeSearch,
      user,
      section,
      local,
    };
  }, [
    page,
    limit,
    filterFreeSearch,
    user,
    section,
    local,
  ]);

  const loadUserType = useCallback(async () => {
    const idType = await getIdTipo(user)
    return setUserType(idType);
  }, [user]);

  const loadLeads = useCallback(async ({ filtersMemo, fetchPayloadMemo }) => {
    const { filterFreeSearch, limit, local, page, section, user } = fetchPayloadMemo;
    setLoading(true);
    const list = await post(API.GET_BUY_LEADS, {
      page,
      limit,
      filters: {
        ...filtersMemo,
        filterDateStart: filtersMemo.filterDateStart ? moment(filtersMemo.filterDateStart) : "",
        filterDateEnd: filtersMemo.filterDateEnd ? moment(filtersMemo.filterDateEnd) : "",
      },
      filterFreeSearch: filterFreeSearch ? filterFreeSearch : "",
      user,
      section,
      local
    });
    if (list.length === 0) {
      setLoading(false);
    }
    if (list.error) {
      return errorToast(<span>{t("errors.errorNoLeads")}</span>);
    }
    setLeads(list);
    setLoading(false);
  }, [t]);

  const loadLeadsCount = useCallback(async ({ filtersMemo, fetchPayloadMemo }) => {
    const { filterFreeSearch, local, limit, page, section, user } = fetchPayloadMemo;
    const list_count = await post(API.GET_BUY_LEADS_COUNT, {
      page,
      limit,
      filters: {
        ...filtersMemo,
        filterDateStart: filtersMemo.filterDateStart ? moment(filtersMemo.filterDateStart) : "",
        filterDateEnd: filtersMemo.filterDateEnd
          ? moment(filtersMemo.filterDateEnd)
          : "",
      },
      filterFreeSearch: filterFreeSearch ? filterFreeSearch : "",
      user,
      section,
      local,
    });
    if (list_count.error) {
      return errorToast(<span>{t("errors.errorNoLeads")}</span>);
    }
    else {
      setCount(list_count.count);
      setLoading(false);
    }
  }, [t]);

  const getLocalNameByID = useCallback(async (id) => {
    const local_name = await post(API.GET_LOCAL_NAME_BY_ID, {
      id,
    });
    const response = local_name.name;
    setFilterLocales([{ value: id, label: response[0].nombre }]);
  }, []);

  useEffect(() => {
    const _filterList = [
      filterLocales,
      filterEstados,
      filterMarcasComerciales,
      filterFormaContacto,
      filterProcedencia,
      filterMarca,
      filterProvincia,
      filterAnno,
      filterKmDesde,
      filterKmHasta,
      filterGestor,
      filterEmpleadoImplicado,
      filterAccionesRealizadas,
    ].filter(x => x);

    const filterListFlat = _filterList.flatMap(list => list.map(item => item.label.toString()));

    const filterDateList = [
      filterDateStart,
      filterDateEnd,
      filterCitaStart,
      filterCitaEnd,
      filterAccionStart,
      filterAccionEnd,
    ].filter(x => x).map(x => formatDateWithoutHour(x));

    setFilterList([...filterListFlat, ...filterDateList]);
  }, [storedFilters]);

  useEffect(() => {
    loadUserType();

    if (storedFilters) {
      setFilterLocales(storedFilters.filterLocales);
      setFilterEstados(storedFilters.filterEstados);
      setFilterMarcasComerciales(storedFilters.filterMarcasComerciales);
      setfilterFreeSearch(storedFilters.filterFreeSearch);
      setFilterFormaContacto(storedFilters.filterFormaContacto);
      setFilterProcedencia(storedFilters.filterProcedencia);
      setFilterMarca(storedFilters.filterMarca);
      setFilterProvincia(storedFilters.filterProvincia);
      setFilterAnno(storedFilters.filterAnno);
      setFilterKmDesde(storedFilters.filterKmDesde);
      setFilterKmHasta(storedFilters.filterKmHasta);
      setFilterGestor(storedFilters.filterGestor);
      setFilterEmpleadoImplicado(storedFilters.filterEmpleadoImplicado);
      setFilterAccionesRealizadas(storedFilters.filterAccionesRealizadas);
      setFilterDateStart(storedFilters.filterDateStart);
      setFilterDateEnd(storedFilters.filterDateEnd);
      setFilterCitaStart(storedFilters.filterCitaStart);
      setFilterCitaEnd(storedFilters.filterCitaEnd);
      setFilterAccionStart(storedFilters.filterAccionStart);
      setFilterAccionEnd(storedFilters.filterAccionEnd);
      setFilterLocalesTodos(storedFilters.filterLocalesTodos);
      setHasFilterChanged(true);
    }
    else {
      setInitialFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    section === "pendientestasacion"
      ? setFilterEstados([
        { label: "Pendiente tasación central", value: 57 },
        { label: "Tasado Preliminiar", value: 60 }
      ])
      : setFilterEstados([]);
    setHasFilterChanged(true);
  }, [section]);

  useEffect(() => {
    if (initialFetch && filterEstados.length > 0) {
      setInitialFetch(false);
      return
    }
    if (hasFilterChanged || initialFetch || hasLimitPageChanged) {
      loadLeads({ filtersMemo, fetchPayloadMemo });
      loadLeadsCount({ filtersMemo, fetchPayloadMemo });
      dispatchLeadsFilters(filtersMemo);

      setHasFilterChanged(false);
      setInitialFetch(false);
      setHasLimitPageChanged(false);
    }

  }, [
    filterLocalesTodos,
    filterEstados,
    initialFetch,
    dispatchLeadsFilters,
    filtersMemo,
    fetchPayloadMemo,
    hasFilterChanged,
    hasLimitPageChanged,
    loadLeads,
    loadLeadsCount,
  ]);




  const changePagination = (actualPage, totalPage) => {
    setLimit(totalPage);
    setPage(actualPage);
    setHasLimitPageChanged(true);
  };

  const changeMultipleMod = (state, id) => {
    if (state) {
      setChangeData([...changeData, id]);
    } else {
      setChangeData(changeData.filter((value) => value !== id));
    }
  };

  const changeMultipleMod_All = (action) => {
    if (action) {
      let list = [];
      leads.forEach((l) => {
        list.push(l.id);
      });
      setChangeData(list);
    } else {
      setChangeData([]);
    }
    setChangeAll(!changeAll);
  };

  const launchChangeMultiple = async (manager, state) => {
    if (changeData.length < 1) {
      return errorToast(<span>{t("errors.errorNoLeadSelected")}</span>);
    }
    if (!manager && !state) {
      return errorToast(<span>{t("errors.errorNoActionSelected")}</span>);
    }
    if (manager) {
      const launchChangeManager = await post(API.CHANGE_LEAD_MANAGER, {
        manager,
        leads: changeData,
      });
      if (launchChangeManager.error) {
        return errorToast(<span>{t("errors.errorChangeAgent")}</span>);
      } else {
        return window.location.reload();
      }
    } else if (state) {
      for (let i = 0; i < changeData.length; i++) {
        const r = await getLastLeadId(changeData[i]);
        const modData = {
          lead: changeData[i],
          estado: state,
          empleado: user,
          estado_anterior: r,
        };
        launchModLeadStatus(
          modData,
          i === changeData.length - 1 ? true : false
        );
      }
    }
  };

  const launchModLeadStatus = async (data, last) => {
    const launchChangeStatus1 = await post(API.CHANGE_LEAD_STATUS, {
      state: data.estado,
      leads: data.lead,
    });
    const launchChangeStatus2 = await post(API.INSERT_LEAD_STATUS, {
      data,
    });
    if (launchChangeStatus1.error || launchChangeStatus2.error) {
      return errorToast(<span>{t("errors.errorChangeState")}</span>);
    }
    if (last) {
      return window.location.reload();
    }
  };
  const changeFreeSearch = (freeSearchValue) => {
    if (freeSearchValue?.length > 2) {
      setfilterFreeSearch(freeSearchValue);
      setHasFilterChanged(true);
    }
    if (freeSearchValue === "" && filterFreeSearch !== null) {
      setfilterFreeSearch(freeSearchValue);
      setHasFilterChanged(true);
    }
  };
  useDebouncedEffect(
    () => changeFreeSearch(freeSearchValue),
    700,
    [freeSearchValue]
  );
  


  const handleDeletedDropdownSelected = (element) => {
    
      const updateFilter = (filter, setFilter) => {
        const index = filter.findIndex((item => item.label.toString() === element));
        if (index !== -1) {
          const newFilter = [...filter];
          newFilter.splice(index, 1);
          setFilter(newFilter);
          setHasFilterChanged(true);
        }
      };
      updateFilter(filterLocales, setFilterLocales);
      updateFilter(filterEstados, setFilterEstados);
      updateFilter(filterMarcasComerciales, setFilterMarcasComerciales);
      updateFilter(filterFormaContacto, setFilterFormaContacto);
      updateFilter(filterProcedencia, setFilterProcedencia);
      updateFilter(filterMarca, setFilterMarca);
      updateFilter(filterProvincia, setFilterProvincia);
      updateFilter(filterAnno, setFilterAnno);
      updateFilter(filterKmDesde, setFilterKmDesde);
      updateFilter(filterKmHasta, setFilterKmHasta);
      updateFilter(filterGestor, setFilterGestor);
      updateFilter(filterEmpleadoImplicado, setFilterEmpleadoImplicado);
      updateFilter(filterAccionesRealizadas, setFilterAccionesRealizadas);

      if (formatDateWithoutHour(filterDateStart) === element) {
        setFilterDateStart(undefined);
        setHasFilterChanged(true);
      }
      if (formatDateWithoutHour(filterDateEnd) === element) {
        setFilterDateEnd(undefined);
        setHasFilterChanged(true);
      }
      if (formatDateWithoutHour(filterCitaStart) === element) {
        setFilterCitaStart(undefined);
        setHasFilterChanged(true);
      }
      if (formatDateWithoutHour(filterCitaEnd) === element) {
        setFilterCitaEnd(undefined);
        setHasFilterChanged(true);
      }
      if (formatDateWithoutHour(filterAccionStart) === element) {
        setFilterAccionStart(undefined);
        setHasFilterChanged(true);
      }
      if (formatDateWithoutHour(filterAccionEnd) === element) {
        setFilterAccionEnd(undefined);
        setHasFilterChanged(true);
      }
  };



  useEffect(() => {
    if (local) setMisLocales(local);
    if (local && local.length === 1) {
      getLocalNameByID(local[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLocalNameByID, local]);

  const filters = (
    <>
      <Button
        outline
        block
        className="mt-2 mb-3 orange-btn d-xl-block d-none"
        onClick={() => {
          if (
            section ===
            ("todos_empenos" ||
              "propios_empenos" ||
              "propios_pendientes_empenos" ||
              "pendientes_empenos")
          ) {
            window.open(
              `${environment.PHP_HOST}index.php?seccion=agregarleed`
            );
          } else {
            window.open(`${environment.REACT_HOST}/main/lead-tasacion`);
            //ABRIR MODAL

          }
        }}
      >
        {t('createLead')}
      </Button>
      <Button
        outline
        color="danger"
        block
        className="mt-2 mb-3"
        onClick={() => {
          setFilterLocales([]);
          setFilterEstados(
            section === "poca_tasacion"
              ? [
                { label: "Poca Tasacion", value: 5 },
                { label: "Coche interesante no comprado", value: 53 },
              ]
              : section === "pendientestasacion"
                ? [
                  { label: "Pendiente tasación central", value: 57 },
                  { label: "Tasado Preliminiar", value: 60 },
                ]
                : []
          );
          setFilterFormaContacto([]);
          setFilterProcedencia([]);
          setFilterMarca([]);
          setfilterFreeSearch("");
          setFilterMarcasComerciales([]);
          setFilterProvincia([]);
          setFilterAnno([]);
          setFilterKmDesde([]);
          setFilterKmHasta([]);
          setFilterGestor([]);
          setFilterEmpleadoImplicado([]);
          setFilterAccionesRealizadas([]);
          setFilterDateStart(          
            new Date(new Date().setHours(0, 0, 0)).setMonth(
              new Date().getMonth() - 4
            )
          );
          setFilterDateEnd();
          setFilterCitaStart();
          setFilterCitaEnd();
          setFilterAccionStart();
          setFilterAccionEnd();
          setFilterLocalesTodos(false);

          setHasFilterChanged(true);
        }}
      >
        {t('deleteFilters')}
      </Button>
      {misLocales ? (
        <Select
          isMulti
          placeholder={t("locals")}
          loadOptions={() =>
            rol.includes(RolType.CENTRAL_APPRAISER)
              ? getFilterAllDealersihps()
              : getFilterDealerships([misLocales])
          }
          value={filterLocales}
          onChange={(value) => {
            setFilterLocales(value);
            setHasFilterChanged(true);
          }}
          isDisabled={section === 'pendientestasacion' && filterLocalesTodos}
        />
      ) : null}
      {section === 'pendientestasacion' && (
        <Checkbox
          key={"LocalesTodos"}
          name={"Seleccionar Todos"}
          status={filterLocalesTodos}
          onChange={() => {
            setFilterLocalesTodos(!filterLocalesTodos)
            setHasFilterChanged(true)
          }}
        />)}
      <br />
      <Select
        isMulti
        placeholder={t("commercialBrands")}
        loadOptions={() => getFilterCommercialBrands(user)}
        value={filterMarcasComerciales}
        onChange={(value) => {
          setFilterMarcasComerciales(value);
          setHasFilterChanged(true);
        }}
      />
      <br />
      <Select
        isMulti
        placeholder={t("actualState")}
        loadOptions={getFilterActualState}
        value={filterEstados}
        onChange={(value) => {
          setFilterEstados(value);
          setHasFilterChanged(true);
        }}
      />
      <br />
      <Select
        isMulti
        placeholder={t("contactForm")}
        loadOptions={getFilterContact}
        value={filterFormaContacto}
        onChange={(value) => {
          setFilterFormaContacto(value);
          setHasFilterChanged(true);
        }}
      />
      <br />
      <Select
        isMulti
        placeholder={t("origin")}
        loadOptions={getFilterOrigin}
        value={filterProcedencia}
        onChange={(value) => {
          setFilterProcedencia(value);
          setHasFilterChanged(true);
        }}
      />
      <br />
      <Select
        isMulti
        placeholder={t("brand")}
        loadOptions={getFilterVehicleBrand}
        value={filterMarca}
        onChange={(value) => {
          setFilterMarca(value);
          setHasFilterChanged(true);
        }}
      />
      <br />
      <Select
        isMulti
        placeholder={t("province")}
        loadOptions={getFilterProvincesLiteral}
        value={filterProvincia}
        onChange={(value) => {
          setFilterProvincia(value);
          setHasFilterChanged(true);
        }}
      />
      <br />
      <Select
        isMulti
        placeholder={t("year")}
        loadOptions={getFilterYear}
        value={filterAnno}
        onChange={(value) => {
          setFilterAnno(value);
          setHasFilterChanged(true);
        }}
      />
      <br />
      <div className="d-flex flex-column" style={{ gap: "10px" }}>
        <Select
          placeholder={t("kmsFrom")}
          loadOptions={getFilterKmsFrom}
          value={filterKmDesde}
          onChange={(value) => {
            setFilterKmDesde([value]);
            setHasFilterChanged(true);
          }}
        />
        <Select
          placeholder={t("kmsUntil")}
          loadOptions={getFilterKmsUntil}
          value={filterKmHasta}
          onChange={(value) => {
            setFilterKmHasta([value]);
            setHasFilterChanged(true);
          }}
        />
      </div>
      <br />
      {userType && (
        <React.Fragment>
          <Select
            isMulti
            placeholder={t("gestor")}
            loadOptions={() => getFilterGestor([userType, user, userType])}
            value={filterGestor}
            onChange={(value) => {
              setFilterGestor(value);
              setHasFilterChanged(true);
            }}
          />
          <br />
          <Select
            isMulti
            placeholder={t("employee")}
            loadOptions={() => getFilterInvolvedEmployee([userType, user, userType])}
            value={filterEmpleadoImplicado}
            onChange={(value) => {
              setFilterEmpleadoImplicado(value);
              setHasFilterChanged(true);
            }}
          />
          <br />
        </React.Fragment>
      )}
      <Select
        isMulti
        placeholder={t("actionsDone")}
        loadOptions={getFilterActionsDone}
        value={filterAccionesRealizadas}
        onChange={(value) => {
          setFilterAccionesRealizadas(value);
          setHasFilterChanged(true);
        }}
      />
      <br />
      <div className="flex flex-row flex-xl-column" style={{ gap: "10px" }}>
        <DatePicker
          date={filterDateStart}
          setDate={(e) => {
            setFilterDateStart(e);
            setHasFilterChanged(true);
          }}
          titleHolder="Leads creados desde"
        />
        <DatePicker
          date={filterDateEnd}
          setDate={(e) => {
            setFilterDateEnd(e);
            setHasFilterChanged(true);
          }}
          titleHolder={t('leadsCreatedUntil')}
        />
      </div>
      <br />
      <div className="flex flex-row flex-xl-column" style={{ gap: "10px" }}>
        <DatePicker
          date={filterCitaStart}
          setDate={(e) => {
            setFilterCitaStart(e);
            setHasFilterChanged(true);
          }}
          titleHolder={t('leadsCalledFrom')}
        />
        <DatePicker
          date={filterCitaEnd}
          setDate={(e) => {
            setFilterCitaEnd(e);
            setHasFilterChanged(true);
          }}
          titleHolder={t('leadsCalledUntil')}
        />
      </div>
      <br />
      <div className="flex flex-row flex-xl-column" style={{ gap: "10px" }}>
        <DatePicker
          date={filterAccionStart}
          setDate={(e) => {
            setFilterAccionStart(e);
            setHasFilterChanged(true);
          }}
          titleHolder={t('actionFrom')}
        />
        <DatePicker
          date={filterAccionEnd}
          setDate={(e) => {
            setFilterAccionEnd(e);
            setHasFilterChanged(true);
          }}
          titleHolder={t('actionUntil')}
        />
      </div>
      <input
        onChange={(e) => setFreeSearchValue(e.target.value)}
        className="form-control ds-input mt-4 mb-3"
        value={freeSearchValue}
        placeholder={t('search')}
      />
    </>
  );

  const launchModalFilter = () => setModalFilter((prev) => !prev);

  return (
    <>
      <Mainlayout>
        <Mainlayout.Sidebar>{filters}</Mainlayout.Sidebar>

        <Mainlayout.Content
          contentTitle={
            section === "todos"
              ? t('leadsCompra')
              : section === "propios"
                ? "LEADS PROPIOS DE COMPRA"
                : section === "pendientes"
                  ? "LEADS PENDIENTES"
                  : section === "propios_pendientes"
                    ? "LEADS PROPIOS PENDIENTES"
                    : section === "pendientestasacion"
                      ? "LEADS COMPRA PENDIENTES TASACIÓN"
                      : "LEADS COMPRA"
          }
        >
          <div className="d-flex d-xl-none m-0 mb-3 m-sm-3">
            <Button
              outline
              block
              className="p-3 orange-btn"
              onClick={() => {
                if (
                  section ===
                  ("todos_empenos" ||
                    "propios_empenos" ||
                    "propios_pendientes_empenos" ||
                    "pendientes_empenos")
                ) {
                  window.open(
                    `${environment.PHP_HOST}index.php?seccion=agregarleed`
                  );
                } else {
                  window.open(`${environment.REACT_HOST}/main/lead-tasacion`);
                }
              }}
            >
              {t('createLead')}
            </Button>
          </div>

          <div className="d-flex flex-wrap mb-2 mt-1">
            <MultiSelectChipsList
              dropdownSelected={filterList}
              deleteDropdownSelected={handleDeletedDropdownSelected}
            />
          </div>
          <div className="d-flex flex-column flex-lg-row w-100 justify-content-between align-items-center">
            {userType <= 2 && (
              <div className="d-flex flex-row align-items-center">
                <div className="d-flex flex-column flex-md-row">
                  <Button
                    outline
                    className={`ml-0 mt-2 mb-3 mt-md-0 mb-md-0 ml-md-3 ${changeType === 1 ? "orange-btn-active" : "orange-btn"
                      }`}
                    onClick={() => {
                      setChangeType(changeType === 1 ? null : 1);
                    }}
                  >
                    {changeType === 1 ? "⚊" : "✚"} {t('changeState')}
                  </Button>
                  <Button
                    outline
                    className={`ml-0 mb-2 mb-md-0 ml-md-3 ${changeType === 2 ? "orange-btn-active" : "orange-btn"
                      }`}
                    onClick={() => {
                      setChangeType(changeType === 2 ? null : 2);
                    }}
                  >
                    {changeType === 2 ? "⚊" : "✚"} {t('changeAgent')}
                  </Button>
                </div>

                <Button
                  outline
                  className="ml-3 mt-2 mt-md-0 d-xl-none d-block"
                  style={{ height: "max-content" }}
                  onClick={launchModalFilter}
                >
                  <FiFilter size={25} />
                </Button>
              </div>
            )}

            <div className={styles.pagination}>
              <Pagination
                changePagination={changePagination}
                showNumb={limit}
              />
              <span className="ml-0 ml-md-4 text-success">
                {t("title")}:{" "}
                <span className="text-primary">
                  {loading || count === null ? (
                    <Spinner size="sm" type="grow" className="flexicarColor" />
                  ) : (
                    count
                  )}
                </span>
              </span>
            </div>
          </div>

          <div
            className="w-100 d-flex ml-0 mt-4 mb-4 ml-lg-3 flex-column flex-md-row"
            style={{ gap: "15px" }}
          >
            <div className={styles.select_agent_state_lead}>
              {changeType === 1 && (
                <Select
                  placeholder="Estado del lead"
                  loadOptions={getFilterActualState}
                  value={changeState}
                  onChange={(value) => setChangeState([value])}
                />
              )}

              {changeType === 2 && (
                <Select
                  placeholder="Agente del lead"
                  loadOptions={getFilterAgent}
                  value={changeManager}
                  onChange={(value) => setChangeManager([value])}
                />
              )}
            </div>
            {changeType != null ? (
              <>
                <Button
                  outline
                  className="orange-btn"
                  onClick={() => {
                    launchChangeMultiple(
                      changeManager[0]?.value,
                      changeState[0]?.value
                    );
                  }}
                >
                  Guardar
                </Button>
                <Button
                  outline
                  className="orange-btn"
                  onClick={() => changeMultipleMod_All(changeAll)}
                >
                  {changeAll ? "Seleccionar todos" : "Deseleccionar todos"}
                </Button>
              </>
            ) : (
              ""
            )}
          </div>

          <div className={styles.table_container}>
            <table className="table">
              <thead>
                <tr>
                  <th
                    className={`${changeType != null ? "d-block" : "d-none"}`}
                    scope="col-md-1"
                  >
                    ✔
                  </th>
                  <th scope="col-md-2" style={{ minHeight: "75px" }}>
                    {t('register')}
                  </th>
                  <th scope="col-md-1">{t('modif')}</th>
                  <th scope="col-md-1">{t('state')}</th>
                  <th scope="col-md-1">{t('origin')}</th>
                  <th scope="col-md-1">{t('client')}</th>
                  <th
                    scope="col-md-1"
                  >
                    {t('province')}
                  </th>
                  <th
                    scope="col-md-1"
                  >
                    {t('phone')}
                  </th>
                  <th
                    scope="col-md-1"
                  >
                    {t('email')}
                  </th>
                  <th scope="col-md-1">{t('brand2')}</th>
                  <th scope="col-md-1">{t('actions')}</th>
                </tr>
              </thead>
              {leads.length > 0 ? (
                <tbody className={loading && "inactive"}>
                  {leads?.map((l) => (
                    <tr key={l.id} style={{ fontSize: "15px" }}>
                      <td
                        className={`${changeType != null ? "d-inherit" : "d-none"
                          }`}
                      >
                        <input
                          onChange={(e) =>
                            changeMultipleMod(e.target.checked, l.id)
                          }
                          className={styles.checkboxL}
                          type="checkbox"
                          checked={changeData.includes(l.id)}
                        />
                      </td>
                      <td data-label="Alta">
                        <div
                          className="d-flex flex-lg-column align-items-center"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={moment(l.fecha).format("YYYY")}
                        >
                          <span>{moment(l.fecha_alta).format("DD/MM")}</span>
                          <span className="ml-1 ml-md-0">
                            {moment(l.fecha_alta).format("HH:mm")}
                          </span>
                        </div>
                      </td>
                      <td data-label="Modificación">
                        <div
                          className="d-flex flex-lg-column align-items-center"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={moment(l.fecha).format("YYYY")}
                        >
                          <span>
                            {moment(l.fecha).format("DD/MM")} <br />
                          </span>
                          <span className="ml-1 ml-md-0">
                            {moment(l.fecha).format("HH:mm")}
                          </span>
                        </div>
                      </td>
                      <td data-label="Estado">
                        <span>{l.estado}</span>
                      </td>
                      <td data-label="Procedencia">
                        <span>{l.procedencia}</span>
                      </td>
                      <td data-label="Cliente">
                        <span>{l.cliente}</span>
                      </td>
                      <td
                        data-label="Provincia"
                      >
                        <span>{l.provincia && l.provincia}</span>
                      </td>
                      <td
                        data-label="Teléfono"
                      >
                        {l.telefono && (
                          <span>
                            <tcxspan
                              tcxhref={l.telefono}
                              title={`Call ${l.telefono} via 3CX`}
                            >
                              {l.telefono}
                            </tcxspan>
                          </span>
                        )}
                      </td>
                      <td
                        data-label="Email"
                        title={l.email && l.email}
                      >
                        <span>
                          {l.email && l.email.length < 23
                            ? l.email && l.email
                            : l.email && l.email?.substring(0, 22) + "..."}
                        </span>
                      </td>
                      <td data-label="Marca">
                        <span>{l.marca && l.marca}</span>
                      </td>
                      <td>
                        <div>
                          <button>
                            <a
                              href={`/main/lead-tasacion/${l.id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <HiDocumentText
                                size={25}
                                color="#007bff"
                                title="Ver lead"
                              />
                              <span>Ver Lead</span>
                            </a>
                          </button>

                          <button>
                            <a
                              href={`${environment.REACT_HOST}/main/nuevo_contrato/${l.id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <HiDocumentAdd
                                size={25}
                                color="#007bff"
                                title="Añadir contrato"
                              />
                              <span>Añadir contrato</span>
                            </a>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : leads.length === 0 ? (
                <h6>No existen leads disponibles</h6>
              ) : (
                <Loading />
              )}
            </table>
          </div>

          {leads.length > 0 && (
            <PaginationFooter
              paginationMin={page}
              total={count}
              showNumb={limit}
              elements={leads}
              changePagination={changePagination}
            />
          )}
        </Mainlayout.Content>
      </Mainlayout>

      {modalFilter && (
        <ModalFilters
          footerButtonText="Ver Leads"
          state={modalFilter}
          dispose={launchModalFilter}
        >
          {filters}
        </ModalFilters>
      )}



    </>
  );
};

const mapStateToProps = (store) => ({
  storedFilters: store.filters.leads,
});

const mapDispatch = dispatcher(["leadsFilters"]);

export default connect(mapStateToProps, mapDispatch)(LeadsList);
