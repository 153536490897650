import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useHistory } from "react-router-dom";
import { MdOutlineEdit, MdContacts } from "react-icons/md";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFlag2Line } from "react-icons/ri";
import { AiOutlineMenu } from "react-icons/ai";

const TabPaneHeader = ({
  title,
  launchModal,
  buttonType,
  setImpresion,
  vehicle,
}) => {
  const history = useHistory();
  const [showMobileButtons, setShowMobileButtons] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 850px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 850px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  return (
    <>
      <div className={`clearfix mb-3 ${styles.container}`}>
        <span className="tittle ml-4">{title}</span>
        <div className="tittle-rigth mr-3">
          {!matches ? (
            <>
                <AiOutlineMenu
                  className="ml-3"
                  size={24}
                  color="#FFFFFF"
                  onClick={() => setShowMobileButtons(!showMobileButtons)}
                ></AiOutlineMenu>
              {showMobileButtons && (
                <>
                <div className={styles.backDrop}
                onClick={() => setShowMobileButtons(false)}
                > </div>
                <div className={styles.containerMenu}>
                <div className={showMobileButtons ? styles.dropdown : ""}>
                  {(buttonType === "vehicle" || buttonType === "infoadicional") && (
                    <button
                      className="btn-flexicar-orange mr-3"
                      onClick={() => {
                        history.push(
                          buttonType === "infoadicional" 
                            ? `/main/edicion-vehiculo/${vehicle.vehicle_id_flexicar}?tab=infoadicional&vehicleId=${vehicle.vehicle_id}` 
                            : `/main/edicion-vehiculo/${vehicle.vehicle_id_flexicar}?tab=datos&vehicleId=${vehicle.vehicle_id}`
                        );
                        setShowMobileButtons(!showMobileButtons);
                      }}
                    >
                      <MdOutlineEdit
                        size={24}
                        color="#FFFFFF"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Editar Vehículo"
                        role="button"
                        className={`mr-2 ${styles.dropdownButton}`}
                      />
                      Editar
                    </button>
                  )}

                  {(buttonType === "lead" || buttonType === "vehicle") && (
                    <button
                      onClick={() => {
                        launchModal("modal_check", vehicle.vehicle_id);
                        setShowMobileButtons(!showMobileButtons);
                      }}
                      className="btn-flexicar-orange mr-3"
                    >
                      <MdContacts
                        size={24}
                        color="#FFFFFF"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Crear Lead"
                        role="button"
                        className="mr-2"
                      />
                      Crear Lead
                    </button>
                  )}
                  {buttonType === "preparacion" && (
                    <button
                  className="btn-flexicar-orange mr-3"
                  onClick={() =>
                    history.push(
                      `/main/vehicle/${vehicle}/registro-preparacion`
                    )
                  }
                >
                  Acciones

                </button>
                    )}

                  {buttonType === "caso" && (
                    <button
                      onClick={() => {
                        history.push(
                          `/main/vehicle/${vehicle.vehicle_id}/crear-caso`
                        );
                      }}
                      className="btn-flexicar-orange mr-3"
                    >
                      <RiFlag2Line
                        size={24}
                        color="#FFFFFF"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Crear Caso"
                        role="button"
                        className="mr-2"
                      />
                      Crear Caso
                    </button>
                  )}

                  {buttonType === "vehicle" && (
                    <button
                      onClick={() => {
                        setImpresion(true);
                        setShowMobileButtons(!showMobileButtons);
                      }}
                      className="btn-flexicar-orange mr-3"
                    >
                      <BsFilePdfFill
                        size={24}
                        color="#FFFFFF"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Imprimir PDF"
                        role="button"
                        className="mr-2"
                      />
                      Imprimir PDF
                    </button>
                  )}
                  </div>
                </div>
              </>
              )}
            </>
          ) : (
            <div>
              {(buttonType === "vehicle" || buttonType === "infoadicional") && (
                <button
                  className="btn-flexicar-orange mr-3"
                  onClick={() =>
                    history.push(
                      buttonType === "infoadicional" 
                        ? `/main/edicion-vehiculo/${vehicle.vehicle_id_flexicar}?tab=infoadicional&vehicleId=${vehicle.vehicle_id}` 
                        : `/main/edicion-vehiculo/${vehicle.vehicle_id_flexicar}?tab=datos&vehicleId=${vehicle.vehicle_id}`
                    )
                  }
                >
                  <MdOutlineEdit
                    size={24}
                    color="#FFFFFF"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Editar Vehículo"
                    role="button"
                    className="mr-2"
                  />
                  Editar
                </button>
              )}
              {(buttonType === "lead" || buttonType === "vehicle") && (
                <button
                  onClick={() => launchModal("modal_check", vehicle.vehicle_id)}
                  className="btn-flexicar-orange mr-3"
                >
                  <MdContacts
                    size={24}
                    color="#FFFFFF"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Crear Lead"
                    role="button"
                    className="mr-2"
                  />
                  Crear Lead
                </button>
                )}
                 {buttonType === "preparacion" && (
                    <button
                  className="btn-flexicar-orange mr-3"
                  onClick={() =>
                    history.push(
                      `/main/vehicle/${vehicle}/registro-preparacion`
                    )
                  }
                >
                  Acciones

                </button>
                )}
               
              
              {buttonType === "vehicle" && (
                <button
                  onClick={() => setImpresion(true)}
                  className="btn-flexicar-orange mr-3"
                >
                  <BsFilePdfFill
                    size={24}
                    color="#FFFFFF"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Imprimir PDF"
                    role="button"
                    className="mr-2"
                  />
                  Imprimir PDF
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default TabPaneHeader;
