import { parseToRSOptions } from "@utils/mapOptions";
import { vehicleValidationWarrantyOptions } from "@constants/selectOptions";
import { VEHICLE_MODE } from "@constants/vehicleValidation";

export const mapVehicleValidationDataToForm = (vehicle) => ({
	mode: vehicle.mode === VEHICLE_MODE.JATO ? true : false,
	make: parseToRSOptions(vehicle.make),
	model: parseToRSOptions(vehicle.model),
	year: { label: vehicle.year, value: vehicle.year },
	fuel: parseToRSOptions(vehicle.fuel),
	transmission: parseToRSOptions(vehicle.transmission),
	bodyType: parseToRSOptions(vehicle.bodyType),
	version:
		vehicle.mode === VEHICLE_MODE.JATO
			? {
					label: `${vehicle.version.name || ""} - ${
						vehicle.specs.doors ? `${vehicle.specs.doors}P` : ""
					} (${vehicle.modelYear || "-"})`,
					value: vehicle.version.id
			  }
			: vehicle.version.name,
	registrationDate: vehicle.registrationDate,
	specs: {
		cc: vehicle.specs.cc,
		power: vehicle.specs.power,
		emissions: vehicle.specs.emissions,
		cubinVolume: vehicle.specs.cubinVolume,
		consumptionMix: vehicle.specs.consumptionMix,
		consumptionRoad: vehicle.specs.consumptionRoad,
		consumptionUrban: vehicle.specs.consumptionUrban,
		seats: vehicle.specs.seats,
		doors: vehicle.specs.doors,
		gearCount: vehicle.specs.gearCount,
		ecosticker: vehicle.specs.ecosticker ? parseToRSOptions(vehicle.specs.ecosticker) : null
	},
	mileage: vehicle.mileage,
	warranty: vehicle.warrantyDate
		? vehicleValidationWarrantyOptions[0]
		: vehicleValidationWarrantyOptions[1],
	warrantyDate: vehicle.warrantyDate,
	origin: parseToRSOptions(vehicle.origin),
	color: parseToRSOptions(vehicle.color),
	upholsteryColor: vehicle.upholsteryColor,
	itvDueDate: vehicle.itvDueDate,
	itvLastDate: vehicle.itvLastDate,
	itvResult: vehicle.itvResult,
	noItv: vehicle.noItv,
	km0: vehicle.km0,
	uOwner: vehicle.uOwner,
	imported: vehicle.imported
});

export const mapVehicleValidationDataFromForm = (formData) => ({
	mode: formData.mode ? VEHICLE_MODE.JATO : VEHICLE_MODE.CLASSIC,
	...(!formData.mode && {
		modelId: formData.model.value,
		fuelId: formData.fuel.value,
		bodyTypeId: formData.bodyType.value,
		versionName: formData.version,
		specs: {
			cc: formData.specs.cc,
			power: formData.specs.power,
			emissions: formData.specs.emissions,
			cubinVolume: formData.specs.cubinVolume,
			consumptionUrban: formData.specs.consumptionUrban,
			consumptionMix: formData.specs.consumptionMix,
			consumptionRoad: formData.specs.consumptionRoad,
			seats: formData.specs.seats,
			doors: formData.specs.doors,
			gearCount: formData.specs.gearCount,
			ecostickerId: formData.specs.ecosticker?.value
		}
	}),
	...(formData.mode && {
		versionId: formData.versionId
	}),
	transmissionId: formData.transmission.value,
	registrationDate: formData.registrationDate,
	mileage: formData.mileage,
	warrantyDate: formData.warrantyDate,
	originId: formData.origin.value,
	colorId: formData.color.value,
	upholsteryColor: formData.upholsteryColor || undefined,
	itvDueDate: formData.itvDueDate,
	itvLastDate: formData.itvLastDate,
	itvResult: formData.itvResult?.value,
	km0: formData.km0,
	uOwner: formData.uOwner
});
