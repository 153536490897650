import * as Yup from "yup";

const vehicleValidationAdditionalInfoFormSchema = ({ t }) =>
	Yup.object({
		lastInspectionDate: Yup.string().nullable().isISODateString(true),
		inspectionKm: Yup.number().min(0, t("errors.positive")).integer(t("errors.integer")),
		cambeltReplace: Yup.boolean(),
		cambeltReplaceKm: Yup.number().when(["$cambeltReplace"], ([cambeltReplace], schema) =>
			cambeltReplace
				? schema
						.required(t("errors.mandatory"))
						.min(0, t("errors.positive"))
						.integer(t("errors.integer"))
				: schema.notRequired()
		),
		comment: Yup.string().max(300, t("errors.max", { max: 300 }))
	});

export { vehicleValidationAdditionalInfoFormSchema };
