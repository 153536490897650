import * as Yup from "yup";

import { PLATE_REGEX_ES, REGEX_VIN } from "@constants/regex";

export const sellContractVehicleSchema = Yup.object({
	plate: Yup.string().test({
		name: "plateValidation",
		message: "Matrícula inválida",
		test: (value, ctx) => {
			if (!PLATE_REGEX_ES.test(value)) {
				ctx.createError({ path: "plate", message: ctx.message });
				return false;
			}
			return true;
		}
	}),
	km: Yup.number()
		.moreThan(0, "Los Km deben ser mayor que 0")
		.integer("No se admiten decimales")
		.max(999999, "Km debe ser igual o inferior a 999.999"),
	bastidor: Yup.string().test({
		name: "bastidorValidation",
		message: "Bastidor inválido",
		test: (value, ctx) => {
			if (!REGEX_VIN.test(value)) {
				ctx.createError({ path: "plate", message: ctx.message });
				return false;
			}
			return true;
		}
	}),
	dealership: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	dealershipProvince: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	companie: Yup.string(),
	iva: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).required("Campo obligatorio"),
	dateLastITV: Yup.string().nullable().isISODateString(true),
	dateExpirationITV: Yup.string().nullable().isISODateString(true),
	conformidades: Yup.string().nullable().max(300, "Máximo 300 caracteres")
});
