export const mapVehicleValidationAdditionalInfoToForm = (vehicle) => ({
	lastInspectionDate: vehicle.lastInspectionDate,
	inspectionKm: vehicle.inspectionKm ?? undefined,
	cambeltReplace: vehicle.cambeltReplaceKm ? true : false,
	cambeltReplaceKm: vehicle.cambeltReplaceKm ?? undefined,
	comment: vehicle.comment?.text ?? ""
});

export const mapVehicleValidationAdditionalInfoFromForm = (formData) => ({
	lastInspectionDate: formData.lastInspectionDate ?? null,
	inspectionKm: formData.inspectionKm && formData.inspectionKm !== 0 ? formData.inspectionKm : null,
	cambeltReplaceKm:
		formData.cambeltReplace && formData.cambeltReplaceKm !== 0 ? formData.cambeltReplaceKm : null,
	comment: formData.comment || null
});
