import { Controller, useFormContext } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import ESDateLocale from "date-fns/locale/es";
import PTDateLocale from "date-fns/locale/pt";
import "react-datepicker/dist/react-datepicker.css";

import styles from "@styles/components/Form/date-picker.module.scss";
import useFormError from "@components/Form/hooks/useFormError";
import Locale from "@constants/Locale";
import { parseDateToISODate } from "@helpers/formatDate";

/**
 * @typedef {import("react-datepicker").ReactDatePickerProps} ReactDatePickerProps
 */

/**
 * @typedef Props
 * @property {string} label - The label text for the date picker
 */

/**
 * FormDatePicker component for rendering a date picker
 *
 * @param {Props & ReactDatePickerProps} props - Props for the FormDatePicker component
 * @returns {JSX.Element} The rendered component.
 */
const FormDatePicker = ({ label, name, ...datePickerOptions }) => {
	const { control } = useFormContext();
	const { markupError } = useFormError(name);
	const { i18n } = useTranslation();

	return (
		<div className={styles.customDatePickerWrapper}>
			<label>
				{label}

				<Controller
					name={name}
					control={control}
					render={({ field }) => {
						const { value, ref, onChange, ...newField } = field;
						return (
							<ReactDatePicker
								ref={(elem) => {
									elem && ref(elem.input);
								}}
								clearButtonClassName={styles.reactDatepickerCloseIcon}
								toggleCalendarOnIconClick
								isClearable
								{...newField}
								{...datePickerOptions}
								selected={value && !isNaN(new Date(value)) ? new Date(value) : null}
								onChange={(date) => {
									onChange(parseDateToISODate(date));
								}}
								dateFormat="dd-MM-yyyy"
								locale={
									{
										[Locale.ES]: ESDateLocale,
										[Locale.PT]: PTDateLocale
									}[i18n.language]
								}
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
							/>
						);
					}}
				/>
			</label>

			{markupError}
		</div>
	);
};

export default FormDatePicker;
