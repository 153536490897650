import * as Yup from "yup";

import { transportDeliveryTypeOptions, yesOrNotSelect } from "@constants/selectOptions";
import { postalCodeEuRegex } from "@constants/regex";
import validationIBAN from "@helpers/validationIBAN";

const percentageCourtesy = 10;
// Financia
const financing = Yup.object({
	wants: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).required("Campo obligatorio"),
	discount: Yup.number().when(["$sellInfo.financing.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelect[0].value
			? schema.required("Campo obligatorio").min(0, "El campo debe ser mayor o igual que 0")
			: schema.notRequired()
	),
	financialEntity: Yup.object({
		label: Yup.string(),
		value: Yup.string()
	}).when(["$sellInfo.financing.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelect[0].value
			? schema.required("Campo obligatorio")
			: schema.notRequired()
	),
	TIN: Yup.number().when(["$sellInfo.financing.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelect[0].value
			? schema.isNumberWithLessThanNDecimals(2)
			: schema.notRequired()
	),
	term: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).when(["$sellInfo.financing.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelect[0].value
			? schema.required("Campo obligatorio")
			: schema.notRequired()
	),
	quota: Yup.number().when(["$sellInfo.financing.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelect[0].value
			? schema.moreThan(0, "El campo debe ser mayor que 0").isNumberWithLessThanNDecimals(2)
			: schema.notRequired()
	)
});

// Transporte
const deliveryAlternativeAddress = Yup.object({
	province: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).when(
		["$sellInfo.transport.delivery.type", "$sellInfo.transport.delivery.useSameAddress"],
		([type, useSameAddress], schema) =>
			type?.value === transportDeliveryTypeOptions[1].value && !useSameAddress
				? schema.required("Campo obligatorio")
				: schema.notRequired()
	),
	town: Yup.string().when(
		["$sellInfo.transport.delivery.type", "$sellInfo.transport.delivery.useSameAddress"],
		([type, useSameAddress], schema) =>
			type?.value === transportDeliveryTypeOptions[1].value && !useSameAddress
				? schema.required("Campo obligatorio")
				: schema.notRequired()
	),
	address: Yup.string().when(
		["$sellInfo.transport.delivery.type", "$sellInfo.transport.delivery.useSameAddress"],
		([type, useSameAddress], schema) =>
			type?.value === transportDeliveryTypeOptions[1].value && !useSameAddress
				? schema.required("Campo obligatorio")
				: schema.notRequired()
	),
	CP: Yup.string().when(
		["$sellInfo.transport.delivery.type", "$sellInfo.transport.delivery.useSameAddress"],
		([type, useSameAddress], schema) =>
			type?.value === transportDeliveryTypeOptions[1].value && !useSameAddress
				? schema.matches(postalCodeEuRegex, "Código postal inválido")
				: schema.notRequired()
	)
});
const delivery = Yup.object({
	amount: Yup.number().when(["$sellInfo.transport.wants"], ([wants], schema) =>
		wants?.value
			? schema.min(0, "El campo debe ser mayor o igual que 0").integer("No se admiten decimales")
			: schema.notRequired()
	),
	type: Yup.object({
		label: Yup.string(),
		value: Yup.string()
	}).when(["$sellInfo.transport.wants"], ([wants], schema) =>
		wants?.value ? schema.required("Campo obligatorio") : schema.notRequired()
	),
	dealership: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).when(
		["$sellInfo.transport.wants", "$sellInfo.transport.delivery.type"],
		([wants, type], schema) =>
			wants?.value && type?.value === transportDeliveryTypeOptions[0].value
				? schema.required("Campo obligatorio")
				: schema.notRequired()
	),
	provinceDealership: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).when(
		["$sellInfo.transport.wants", "$sellInfo.transport.delivery.type"],
		([wants, type], schema) =>
			wants?.value && type?.value === transportDeliveryTypeOptions[0].value
				? schema.required("Campo obligatorio")
				: schema.notRequired()
	),
	useSameAddress: Yup.boolean().when(["$sellInfo.transport.delivery.type"], ([type], schema) =>
		type?.value === transportDeliveryTypeOptions[1].value
			? schema.required("Campo obligatorio")
			: schema.notRequired()
	),
	alternativeAddress: deliveryAlternativeAddress
});

// Garantia
const warranty = Yup.object({
	label: Yup.string(),
	value: Yup.number()
}).required("Campo obligatorio");

const warrantyIBAN = Yup.string().when(["$sellInfo.warranty"], ([warranty], schema) =>
	warranty?.extra
		? schema
				.required("Campo obligatorio")
				.test("isSelectValidation", "IBAN inválido", function (value) {
					const { path, createError } = this;
					if (!validationIBAN(value)) {
						createError({ path, message: this.message });
						return false;
					}
					return true;
				})
		: schema.notRequired()
);

const warrantyAmount = Yup.number().when(["$sellInfo.warranty"], ([warranty], schema) =>
	warranty?.price > 0
		? schema
				.required("Campo Obligatorio")
				.moreThan(0, "El campo debe ser mayor que 0")
				.isNumberWithLessThanNDecimals(2)
		: schema.notRequired()
);

// Seguro
const insurance = Yup.object({
	wants: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).required("Campo obligatorio"),
	company: Yup.object({
		label: Yup.string(),
		value: Yup.string()
	}).when(["$sellInfo.insurance.wants"], ([wants], schema) =>
		wants?.value ? schema.required("Campo obligatorio") : schema.notRequired()
	),
	financing: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).when(["$sellInfo.insurance.wants"], ([wants], schema) =>
		wants?.value
			? schema
					.required("Campo obligatorio")
					.test(
						"checkFinancing",
						"Debes seleccionar “Financia: Sí” en Datos de la venta.",
						function (wants) {
							const { path, createError, from, message } = this;

							const wantsInsuranceFinancing = wants?.value;

							if (
								wantsInsuranceFinancing &&
								from[3].value.sellInfo.financing.wants?.value !== wantsInsuranceFinancing
							) {
								createError({ path, message: message });
								return false;
							}

							return true;
						}
					)
			: schema.notRequired()
	),
	amount: Yup.number().when(["$sellInfo.insurance.wants"], ([wants], schema) =>
		wants?.value
			? schema
					.required("Campo obligatorio")
					.isNumberWithLessThanNDecimals(2)
					.min(1, "Valor minimo: 1 €")
			: schema.notRequired()
	)
});

export const sellContractSellInfoSchema = Yup.object({
	priceType: Yup.number().required("Seleccione una opción"),
	pvp: Yup.number().moreThan(0, "El campo debe ser mayor que 0"),
	ownershipChangeAmount: Yup.number()
		.min(0, "El campo debe ser mayor o igual que 0")
		.integer("No se admiten decimales"),
	gestoria: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	courtesy: Yup.number()
		.min(0, "El campo debe ser mayor o igual que 0")
		.integer("No se admiten decimales")
		.when(["$sellInfo.pvp"], ([pvp], schema) =>
			schema.max(
				(pvp * percentageCourtesy) / 100,
				`No debe ser superior al ${percentageCourtesy}% del PvP venta`
			)
		),
	financing,
	transport: Yup.object({
		sellType: Yup.object({
			label: Yup.string(),
			value: Yup.string()
		}).required("Campo obligatorio"),
		wants: Yup.object({
			label: Yup.string(),
			value: Yup.boolean()
		}).required("Campo obligatorio"),
		delivery
	}),
	warranty,
	warrantyIBAN,
	warrantyAmount,
	insurance
});
