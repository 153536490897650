import React from "react";

import styles from "./index.module.scss";

const CommentsWithLink = ({ text, className }) => {

    const HTTPS = "https://";
    const HTTP = "http://";

    const textList = text.split(" ");

    return (
        <span className={styles[className]}>
            {textList.map((t, index) => {
                if (t.startsWith(HTTPS) || t.startsWith(HTTP)) {
                    return (
                        <a
                            href={t}
                            key={index}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t}
                        </a>
                    );
                } else {
                    return ` ${t} `;
                }
            })}
        </span>
    );
};

export default CommentsWithLink;
