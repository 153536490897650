import React, { useState, useEffect, useContext } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  Container,
  Row,
  Col,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import errorCall from "@services/errorCall";
import revision from "@helpers/revision";
import styles from "./index.module.scss";
import { errorToast, successToast } from "@helpers/toastFunctions";
import DocumentationType from "@constants/documentationType";
import { UserContext } from "@contexts/UserContext";
import RolType from "@constants/roles";
import { useTranslation } from 'react-i18next';
import validationPostalCode from "@helpers/validationPostalCode";
import Locale from "@constants/Locale";
import FormattedNumberInput from "@components/FormattedNumberInput";
import validationIBAN from "@helpers/validationIBAN";
import Select from "@components/Select"
import {
  getFilterCompanies,
  getFilterProvinces,
  getFilterUserCompanies,
  getFilterCompanyDealerships,
  getFilterGestorias
} from "@services/Filtros/filtros";
import ContractId from "@components/ContractId/ContractId";
import EmailAutocomplete from "@components/AutocompleteEmail/Email";

const ModalUpdateContract = ({
  open,
  close,
  document,
  recall,
  recallGarantia,
  user,
}) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation(['contractEdition'])
  const { rol } = useContext(UserContext);

  const [localDocument, setLocalDocument] = useState({});
  const [pago, setPago] = useState();
  const [vehiculo] = useState({
    marca_vehiculo: "",
    modelo: "",
    matriculacion: "",
    combustible: "",
    ccambios: "",
    carroceria: "",
    version: "",
    jato: true,
    id_jato: null,
    modify: false,
  });



  useEffect(() => {
    if (document?.datos) {
      const parseDatos = document.datos;
      if (!parseDatos.id_gestoria) {
        parseDatos.id_gestoria = 0;
        parseDatos.gestoria = "Ninguna";
      }

      if (!parseDatos.meses_financia) {
        parseDatos.meses_financia = 24;
      }

      setLocalDocument(parseDatos);

      if (parseDatos.forma_pago) {
        setPago(parseDatos.forma_pago === "4" ? 4 : null);
      }
    }
  }, [document]);

  useEffect(() => {
    if (localDocument.forma_pago === 4) {
      setPago(localDocument.forma_pago);
    } else {
      setPago();
    }
  }, [localDocument.forma_pago]);

  const onChangeKmValueHandle = (e) => {
    const value = e.target.value;
    const regex = /[^0-9]/
    if (regex.test(value)) {
      e.target.value = value.replace(regex, '');
    }
    handleSimpleInput(e, "kilometros");
  };

  const getEmpresaDataById = async (id) => {
    if (id) {
      const empresaData = await post(API.GET_EMPRESA_BY_ID, { id });
      if (document.tipo === DocumentationType.CONTRATO_VENTA && id && empresaData) {
        setLocalDocument(prev => ({
          ...prev,
          empresa_id: empresaData.res.id,
          empresa_nombre: empresaData.res.nombre,
          sello: empresaData.res.sello,
          empresa_CIF: empresaData.res.CIF,
          empresa_direccion: empresaData.res.direccion,
        }));
      }

      if (document.tipo === DocumentationType.RESERVA) {
        setLocalDocument(prev => ({
          ...prev,
          empresa_id: empresaData.res.id,
          empresa: empresaData.res.nombre,
          sello: empresaData.res.sello,
          empresa_cif: empresaData.res.CIF,
        }));
      }

      if (document.tipo === DocumentationType.COMPRA_DIRECTA || document.tipo === DocumentationType.GESTION_VENTA) {
        setLocalDocument(prev => ({
          ...prev,
          empresa_id: empresaData.res.id,
          empresa_nombre: empresaData.res.nombre,
          // empresa_cuenta: empresaData.res.nombre,
          sello: empresaData.res.sello,
          empresa_CIF: empresaData.res.CIF,
          empresa_direccion: empresaData.res.direccion,
        }));
      }
    }
  };

  const saveUpdate = async () => {
    let revision;

    if (document.tipo === DocumentationType.RESERVA) {
      revision = revisionReseve();
    } else if (document.tipo === DocumentationType.COMPRA_DIRECTA || document.tipo === DocumentationType.GESTION_VENTA) {
      revision = revisionBuy();
    }
    if (revision) {
      try {
        const localDocumentParsed = {
          ...localDocument,
          marca: vehiculo.marca_vehiculo[0]?.label
            ? vehiculo.marca_vehiculo[0].label
            : localDocument.marca,
          modelo: vehiculo.modelo[0]?.label
            ? vehiculo.modelo[0].label
            : localDocument.modelo,
        };

        if (localDocument.pvp) {
          localDocumentParsed.pvp = parseFloat(localDocument.pvp);
          localDocumentParsed.pago_financiado = parseFloat(localDocument.pvp);
        }
        if (localDocument.banco_code) {
          localDocumentParsed.banco_code = parseFloat(localDocument.banco_code);
        }
        if (localDocument.kilometros) {
          localDocumentParsed.kilometros = parseFloat(localDocument.kilometros);
        }
        if (localDocument.id_gestoria) {
          localDocumentParsed.id_gestoria = parseFloat(
            localDocument.id_gestoria
          );
        }
        if (localDocument.id_provincia) {
          localDocumentParsed.id_provincia = parseFloat(
            localDocument.id_provincia
          );
        }
        if (localDocument.meses_financia) {
          localDocumentParsed.meses_financia = parseFloat(
            localDocument.meses_financia
          );
        }
        if (localDocument.comision_tienda) {
          localDocumentParsed.comision_tienda = parseFloat(
            localDocument.comision_tienda
          );
        }
        if (localDocument.comision_financia) {
          localDocumentParsed.comision_financia = parseFloat(
            localDocument.comision_financia
          );
        }
        if (vehiculo.marca_vehiculo) {
          localDocumentParsed.marca = vehiculo.marca_vehiculo[0].label;
        }
        if (vehiculo.modelo) {
          localDocumentParsed.modelo = vehiculo.modelo[0].label;
        }

        const callData = {
          data: localDocumentParsed,
          id: document.documentId,
          matricula_venta: localDocument?.contrato_vinculado_matricula,
          importe: localDocument?.importe_compra,
          typeContractId: document?.tipo
        };

        await post(API.UPDATE_CONTRACT, callData);

        recall();
        if (recallGarantia) {
          recallGarantia();
        }
        close();
        successToast(t("succesToastUpdate"));
      } catch (e) {
        errorCall(t("errorUpdateContract"));
      }
    }
  };

  const revisionReseve = () => {
    const notEmptyInputs = [
      "nombre",
      "telefono",
      "email",
      "pvp",
      "matricula",
      "kilometros",
      "importe",
    ];
    let result = true;
    notEmptyInputs.forEach((e) => {
      if (!revision(t, "empty", e, localDocument[e])) {
        result = false;
      }
    });
    if (localDocument.dni && !revision(t, "Document", "DNI", localDocument.dni)) {
      result = false;
    }
    if (
      localDocument.telefono &&
      !revision(t, "phone", "telefono", localDocument.telefono)
    ) {
      result = false;
    }
    if (localDocument.CP && !revision(t, "CP", "CP", localDocument.CP)) {
      result = false;
    }
    return result;
  };

  const revisionBuy = () => {
    let notEmptyInputs = [
      "nombre",
      "apellido1",
      "apellido2",
      "telefono",
      "email",
      "direccion",
      "CP",
      "provincia",
      "poblacion",
      "importe_compra",
      "matricula",
      "kilometros",
      "forma_pago",
    ];
    
    if (i18n.language === Locale.PT) {
      notEmptyInputs.push("cuenta");
   }
    let result = true;
    notEmptyInputs.forEach((e) => {
      if (!revision(t, "empty", e, localDocument[e])) {
        result = false;
      }
    });
    if (localDocument.dni && !revision(t, "Document", "DNI", localDocument.dni)) {
      result = false;
    }

    if (
      localDocument.telefono &&
      !revision(t, "phone", "telefono", localDocument.telefono)
    ) {
      result = false;
    }
    if (
      localDocument.forma_pago === "3" &&
      !revision(t, "empty", "cuenta", localDocument.cuenta)
    ) {
      result = false;
    }
    if (localDocument.CP && !revision(t, "CP", "CP", localDocument.CP)) {
      result = false;
    }
    if (
      i18n.language === Locale.PT &&
      CONTRACT_PURCHASE &&
      localDocument.reserva_dominio === "1"
    ) {
      result = validateDomainReserve();
    }
    return result;
  };

  const handleSimpleInput = (e, type) => {
    setLocalDocument({ ...localDocument, [type]: e.target.value });
  };

  const handleUpperInput = (e, type) => {
    setLocalDocument({
      ...localDocument,
      [type]: e.target.value.toUpperCase(),
    });
  };

  const validateMatricula = async () => {
    const matricula = localDocument.matricula_contrato_vinculado;

    const matricula_contract = await post(API.GET_CONTRACT, {
      matricula,
    });

    if (matricula_contract && matricula_contract?.[0]?.id) {
      setLocalDocument({
        ...localDocument,
        id_vehiculo_GVO: matricula_contract?.[0].id,
        contrato_vinculado_marca: matricula_contract?.[0].marca,
        contrato_vinculado_modelo: matricula_contract?.[0].modelo,
        contrato_vinculado_matricula: matricula_contract?.[0].matricula,
        vehicle_id_gvo: matricula_contract?.[0].vehicle_id,
      });
    } else {
      errorToast(<span>{t("errors.errorContract")}</span>);
    }
  };

  const validateDomainReserve = () => {
    const checks = {
      missingBancoReserva: {
        condition: revision(
          t,
          "empty",
          "banco_reserva_dominio",
          localDocument.banco_reserva_dominio
        ),
        errorMessage: <span>{t("errors.errorMissingBancoReserva")}</span>
      },
      validValorReserva: {
        condition: localDocument.valor_reserva_dominio > 0,
        errorMessage: <span>{t("errors.errorvalidValorReserva")}</span>
      },
      validIBANorEntidadReferencia: {
        condition:
          (validationIBAN(localDocument.banco_iban) && !localDocument.entidad_referencia_reserva_dominio) ||
          (localDocument.entidad_referencia_reserva_dominio && !validationIBAN(localDocument.banco_iban)),
        errorMessage: <span>{t("errors.errorValidIBANorEntidadReferencia")}</span>
      }
    };

    const conditionsMet = Object.values(checks).every((check) => check.condition);

    if (conditionsMet) {
      return true;
    } else {
      Object.keys(checks).forEach((key) => {
        if (!checks[key].condition) {
          errorToast(<span>{checks[key].errorMessage}</span>);
        }
      });
      return false;
    }
  };
  const TYPE_BOOKING = document?.tipo === DocumentationType.RESERVA
  const CONTRACT_PURCHASE = document?.tipo === DocumentationType.COMPRA_DIRECTA
  const TYPE_MANAGEMENT = document?.tipo === DocumentationType.GESTION_VENTA

  return (
    <Modal className={"modal-xl"} isOpen={open} toggle={close}>
      <ModalHeader>
        <ContractId
          contractType={document?.tipo}
          contractId={document?.documentId}
        >
          {t("dataModification")}
        </ContractId>
      </ModalHeader>
      <ModalBody className="p-4">
        <Container>
          <h4 className={styles.bold}>{t('clientData')}</h4>
          <Row className={styles.centerRow}>
            <Col xs="12" sm="4">
              <Label>{t('name')}</Label>
              <Input
                placeholder={"Nombre"}
                className="form-control ds-input mt-1"
                type="text"
                invalid={localDocument.nombre === ""}
                value={localDocument.nombre}
                onChange={(e) => {
                  handleSimpleInput(e, "nombre");
                }}
              />
            </Col>
            {(TYPE_BOOKING ||
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="4">
                  <Label>{t('firstName')}</Label>
                  <Input
                    placeholder={t('firstName')}
                    className="form-control ds-input mt-1"
                    type="text"
                    value={localDocument.apellido1}
                    invalid={localDocument.apellido1 === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "apellido1");
                    }}
                  />
                </Col>
              )}
            {(TYPE_BOOKING ||
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="4">
                  <Label>{t('secondName')}</Label>
                  <Input
                    placeholder={t('secondName')}
                    className="form-control ds-input mt-1"
                    type="text"
                    value={localDocument.apellido2}
                    invalid={localDocument.apellido2 === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "apellido2");
                    }}
                  />
                </Col>
              )}
            {(
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="4">
                  <Label>{t('idPerson')}</Label>
                  <Input
                    placeholder={t('idPerson')}
                    className="form-control ds-input mt-1"
                    type="text"
                    invalid={localDocument.DNI === ""}
                    value={localDocument.DNI}
                    onChange={(e) => {
                      handleUpperInput(e, "DNI");
                    }}
                  />
                </Col>
              )}
            {TYPE_BOOKING && (
              <Col xs="12" sm="4">
                <Label>{t('idPerson')}</Label>
                <Input
                  placeholder={t('idPerson')}
                  className="form-control ds-input mt-1"
                  type="text"
                  invalid={localDocument.dni === ""}
                  value={localDocument.dni}
                  onChange={(e) => {
                    handleUpperInput(e, "dni");
                  }}
                />
              </Col>
            )}
            <Col xs="12" sm="4">
              <Label>{t('phone')}</Label>
              <Input
                disabled={
                  localDocument.forma_pago === "pasarela" &&
                  TYPE_BOOKING
                }
                placeholder={t('phone')}
                className="form-control ds-input mt-1"
                type="text"
                invalid={localDocument.telefono === ""}
                value={localDocument.telefono}
                onChange={(e) => {
                  handleSimpleInput(e, "telefono");
                }}
              />
            </Col>
            <Col xs="12" sm="4">
              <Label>{t('email')}</Label>
              <EmailAutocomplete
                  name="email"
                  label={t('email')}
                  value={localDocument.email}
                  onChange={(value) => handleSimpleInput({ target: { value } }, "email")}
                  placeholder={t('email')}
                  disabled={localDocument.forma_pago === "pasarela" && TYPE_BOOKING}
                  className="form-control ds-input mt-1"
              />
            </Col>
          </Row>
          <Row className={styles.centerRow}>
            {(
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="3">
                  <Label>{t('direction')}</Label>
                  <Input
                    placeholder={t('direction')}
                    className="form-control ds-input mt-1"
                    invalid={localDocument.direccion === ""}
                    type="text"
                    value={localDocument.direccion}
                    onChange={(e) => {
                      handleSimpleInput(e, "direccion");
                    }}
                  />
                </Col>
              )}
            {(
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="3">
                  <Label>{t("postalCode")}</Label>
                  {localDocument && (
                    <Input
                      placeholder={t("postalCode")}
                      className="form-control ds-input mt-1"
                      type="text"
                      invalid={!validationPostalCode(localDocument.CP)}
                      value={localDocument.CP}
                      onChange={(e) => {
                        handleSimpleInput(e, "CP");
                      }}
                    />
                  )}
                </Col>
              )}
            {(
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="3">
                  <Label>{t('province')}</Label>
                  <div className={styles.filterAdjust}>
                    <Select
                      placeholder={t("province")}
                      loadOptions={getFilterProvinces}
                      value={{
                        label: localDocument?.provincia,
                        value: localDocument?.id_provincia
                      }}
                      onChange={({ label, value }) =>
                        setLocalDocument({
                          ...localDocument,
                          provincia: label,
                          id_provincia: value
                        })
                      }
                    />
                  </div>
                </Col>
              )}

            {(
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="3">
                  <Label>{t('town')}</Label>
                  <Input
                    placeholder={t('town')}
                    className="form-control ds-input mt-1"
                    type="text"
                    invalid={localDocument.poblacion === ""}
                    value={localDocument.poblacion}
                    onChange={(e) => {
                      handleSimpleInput(e, "poblacion");
                    }}
                  />
                </Col>
              )}
          </Row>
          {CONTRACT_PURCHASE && <h4 className={styles.bold}>{t('buyData')}</h4>}

          <Row className={styles.centerRow}>
            {(TYPE_BOOKING) && (
              <Col xs="12" sm="3">
                <Label>{t('price')}</Label>
                <Input
                  placeholder={t('price')}
                  className="form-control ds-input mt-1"
                  type="number"
                  value={localDocument.pvp}
                  invalid={localDocument.pvp === ""}
                  onChange={(e) => {
                    handleSimpleInput(e, "pvp");
                  }}
                />
              </Col>
            )}

            {(CONTRACT_PURCHASE || TYPE_MANAGEMENT) && (
              <>
                <Col xs="12" sm="4">
                  <Label className={"m-0"}>{t('purchaseAmount')} (€)</Label>
                  <FormattedNumberInput
                    placeholder={t('price')}
                    className="form-control ds-input mt-1"
                    value={localDocument.importe_compra}
                    disabled={!rol?.some(
                      (e) =>
                        e === RolType.GENERAL_ADMIN ||
                        e === RolType.CENTRAL_APPRAISER ||
                        e === RolType.ADMINISTRATION
                    )}
                    invalid={localDocument.importe_compra === ""}
                    onChange={(value) => {
                      setLocalDocument({ ...localDocument, importe_compra: value });
                    }}
                    displayType="input"
                  />
                </Col>

                <Col xs="12" sm="4">
                  <Label className={"m-0"}>{t('paymentMethod')}</Label>
                  <div className={styles.filterAdjust}>
                    <Input
                      placeholder={t('selectPaymentMethod')}
                      className="form-control ds-input mt-1 pointer"
                      type="select"
                      value={localDocument.forma_pago}
                      onChange={(e) => {
                        handleSimpleInput(e, "forma_pago");
                      }}
                    >
                      <option value={0}>{t('selectPaymentMethod')}</option>
                      <option value={1}>{t('cash')}</option>
                      <option value={2}>{t('cheque')}</option>
                      <option value={3}>{t('transfer')}</option>
                      {CONTRACT_PURCHASE ? (
                        <option value={4}>{t('carForPay')}</option>
                      ) : null}{" "}
                    </Input>
                  </div>
                </Col>
                {i18n.language === Locale.PT && (
                  <Col xs="12" sm="4">

                    <Label className={"m-0"}>{t('reservaDominio')}*</Label>
                    <Input
                      placeholder={t('reservaDominioChoice')}
                      className="form-control ds-input mt-1 pointer"
                      type="select"
                      value={localDocument.reserva_dominio}
                      onChange={(e) => {
                        handleSimpleInput(e, "reserva_dominio");
                      }}
                    >
                      <option value={0}>{t('No')}</option>
                      <option value={1}>{t('Sí')}</option>
                    </Input>
                  </Col>
                )}
              </>

            )}

            {TYPE_BOOKING && (
              <Col xs="12" sm="3">
                <Label>{t('amount')}</Label>
                <Input
                  disabled={
                    localDocument.forma_pago === "pasarela" &&
                    TYPE_BOOKING
                  }
                  placeholder={t('amount')}
                  className="form-control ds-input mt-1"
                  type="number"
                  value={localDocument.importe}
                  invalid={localDocument.importe === ""}
                  onChange={(e) => {
                    handleSimpleInput(e, "importe");
                  }}
                />
              </Col>
            )}

            {TYPE_MANAGEMENT && (
              <>
                <Col xs="12" sm="3">
                  <Label>Comisión caso de venta (€)</Label>
                  <Input
                    placeholder={"Comisión caso de venta"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.importe_venta}
                    invalid={localDocument.importe_venta === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "importe_venta");
                    }}
                  />
                </Col>
                <Col xs="12" sm="3">
                  <Label>Gastos y com. mensuales (€)</Label>
                  <Input
                    placeholder={"Gastos y comisiones mensuales"}
                    className="form-control ds-input mt-1"
                    type="text"
                    value={localDocument.comision_retirada}
                    invalid={localDocument.comision_retirada === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "comision_retirada");
                    }}
                  />
                </Col>
                <Col xs="12" sm="3">
                  <Label>Penalizacion retirada (€)</Label>
                  <Input
                    placeholder={"Penalizacion en caso de retirada"}
                    className="form-control ds-input mt-1"
                    type="text"
                    value={localDocument.penalizacion_retirada}
                    invalid={localDocument.penalizacion_retirada === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "penalizacion_retirada");
                    }}
                  />
                </Col>
              </>
            )}

          </Row>
          {TYPE_BOOKING && (
            <Col xs="12" sm="12">
              <Label className={styles.companyAdjust}>
                {t('company')}
                <Select
                  placeholder={t("company")}
                  loadOptions={() => getFilterCompanies(document.documentId)}
                  value={{
                    label: localDocument?.empresa,
                    value: localDocument?.empresa_id
                  }}
                  onChange={({ value }) => getEmpresaDataById(value)}
                />
              </Label>
            </Col>
          )}

          {i18n.language === Locale.PT && localDocument.reserva_dominio === '1' && (
            <Row className={styles.centerRow}>
              <Col style={{ marginTop: 'auto' }} xs="12" sm="3">
                <Label className={"m-0"}>{t('bancoDeReservaDePropiedade')}*</Label>
                <Input
                  placeholder={t('bancoDeReservaDePropiedade')}
                  className="form-control ds-input mt-1"
                  type="text"
                  maxLength={50}
                  required={localDocument.reserva_dominio === '1'}
                  value={localDocument.banco_reserva_dominio}
                  onChange={(e) => {
                    handleSimpleInput(e, "banco_reserva_dominio");
                  }}
                />
              </Col>
              <Col style={{ marginTop: 'auto' }} xs="12" sm="3">
                <Label className={"m-0"}>{t('bancoIban')}*</Label>
                <Input
                  placeholder={t('bancoIban')}
                  className="form-control ds-input mt-1"
                  type="text"
                  required={localDocument.reserva_dominio === '1' && !localDocument.entidad_referencia_reserva_dominio}
                  value={localDocument.banco_iban}
                  onChange={(e) => {
                    handleSimpleInput(e, "banco_iban");
                  }}
                />
              </Col>
              <Col style={{ marginTop: 'auto' }} xs="12" sm="3">
                <Label className={"m-0"}>{t('entidadYReferencia')}*</Label>
                <Input
                  placeholder={t('entidadYReferencia')}
                  className="form-control ds-input mt-1"
                  maxLength={50}
                  type="text"
                  required={localDocument.reserva_dominio === '1' && !localDocument.banco_iban}
                  value={localDocument.entidad_referencia_reserva_dominio}
                  onChange={(e) => {
                    handleSimpleInput(e, "entidad_referencia_reserva_dominio");
                  }}
                />
              </Col>
              <Col style={{ marginTop: 'auto' }} xs="12" sm="3">
                <Label className={"m-0"}>{t('valorReserva')}</Label>

                <FormattedNumberInput
                  value={localDocument.valor_reserva_dominio}
                  maxLength={12}
                  onChange={(value) => {
                    setLocalDocument({ ...localDocument, valor_reserva_dominio: value });
                  }}
                  displayType="input"
                />

              </Col>
            </Row>
          )}

          <Row className={styles.centerRow}>
            {(CONTRACT_PURCHASE || TYPE_MANAGEMENT) && (
              <Col xs="12" sm="3">
                <Label className={"m-0"}> </Label>
                {t('company')}
                <Select
                  placeholder={t("company")}
                  loadOptions={() => getFilterUserCompanies(user)}
                  value={{
                    label: localDocument?.empresa_nombre,
                    value: localDocument?.empresa_id
                  }}
                  onChange={({ value }) => getEmpresaDataById(value)}
                />
              </Col>
            )}

            {localDocument?.empresa_id &&
              (CONTRACT_PURCHASE || TYPE_MANAGEMENT) && (
                <Col xs="12" sm="3">
                  <Label className={"m-0"}> </Label>
                  {t('local')}
                  <Select
                    reloadOptions
                    placeholder="Empresas"
                    loadOptions={() => getFilterCompanyDealerships(localDocument.empresa_id)}
                    value={{
                      label: localDocument?.local_nombre,
                      value: localDocument?.local_id
                    }}
                    onChange={({ label, value }) => setLocalDocument(prev => ({
                      ...prev,
                      local_nombre: label,
                      local_id: value
                    }))}
                  />
                </Col>
              )}

            {CONTRACT_PURCHASE && (
              <Col xs="12" sm="3">
                <Label className={"m-0"}>{t('clientBankAccount')}</Label>
                <Input
                  placeholder={t('numberAccount')}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={localDocument.cuenta}
                  invalid={localDocument.cuenta === ""}
                  onChange={(e) => {
                    handleSimpleInput(e, "cuenta");
                  }}
                />
              </Col>

            )}
            {(
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="3">
                  <Label className={styles.companyAdjust}>
                    {t('agency')}
                    <Select
                      placeholder={t('agency')}
                      loadOptions={getFilterGestorias}
                      value={{
                        label: localDocument?.gestoria,
                        value: localDocument?.id_gestoria
                      }}
                      onChange={({ label, value }) => setLocalDocument(prev => ({
                        ...prev,
                        gestoria: label,
                        id_gestoria: value
                      }))}
                    />
                  </Label>
                </Col>
              )}
          </Row>

          <h4 className={styles.bold}>{t('vehicleData')}</h4>
          <Row className={styles.centerRow}>
            <Col xs="12" sm="4">
              <Label>{t('km')}</Label>
              <Input
                placeholder={t('km')}
                className="form-control ds-input mt-1"
                type="number"
                value={localDocument.kilometros}
                invalid={localDocument.kilometros === ""}
                onChange={(e) => {
                  onChangeKmValueHandle(e)
                }}
              />
            </Col>
            <Col xs="12" sm="4">
              <Label>{t('plate')}</Label>
              <Input
                placeholder={t('plate')}
                className="form-control ds-input mt-1"
                type="text"
                value={localDocument.matricula}
                invalid={localDocument.matricula === ""}
                onChange={(e) => {
                  handleSimpleInput(e, "matricula");
                }}
              />
            </Col>
            <Col xs="12" sm="4">
              <Label>{t('chassisNumber')}</Label>
              <Input
                placeholder={t('chassisNumber')}
                className="form-control ds-input mt-1"
                type="text"
                value={localDocument.bastidor}
                invalid={localDocument.bastidor === ""}
                onChange={(e) => {
                  handleSimpleInput(e, "bastidor");
                }}
              />
            </Col>

            {(CONTRACT_PURCHASE || TYPE_MANAGEMENT) && (
              <>

                {CONTRACT_PURCHASE &&
                  pago &&
                  !localDocument.id_vehiculo_GVO ? (
                  <>
                    <div className="p-3 my-2 rounded col-12 col-sm-12">
                      <Toast style={{ maxWidth: "100%" }}>
                        <ToastHeader>Coche Vinculado</ToastHeader>
                        <ToastBody>
                          <Row>
                            <Col xs="12" sm="8">
                              <Input
                                placeholder={"Matricula"}
                                className="form-control ds-input mt-1"
                                value={
                                  localDocument.matricula_contrato_vinculado
                                }
                                invalid={
                                  localDocument.forma_pago === 4 &&
                                  localDocument.matricula_contrato_vinculado ===
                                  ""
                                }
                                onChange={(e) => {
                                  handleSimpleInput(
                                    e,
                                    "matricula_contrato_vinculado"
                                  );
                                }}
                              />
                            </Col>
                            <Col xs="12" sm="4">
                              <Button
                                className="btn-flexicar right no-print"
                                onClick={() => validateMatricula()}
                              >
                                Buscar vehiculo asociado
                              </Button>
                            </Col>{" "}
                          </Row>
                        </ToastBody>
                      </Toast>
                    </div>
                  </>
                ) : null}

                {pago && localDocument.id_vehiculo_GVO ? (
                  <>
                    <div className="p-3 my-2 rounded col-12 col-sm-12">
                      <Toast style={{ maxWidth: "100%" }}>
                        <ToastHeader>Coche Vinculado</ToastHeader>
                        <ToastBody>
                          <Row>
                            <Col xs="12" sm="8">
                              <p>
                                {localDocument.contrato_vinculado_marca}{" "}
                                {localDocument.contrato_vinculado_modelo}{" "}
                                {localDocument.matricula_contrato_vinculado}{" "}
                                <a
                                  href={`/main/vehicle/${localDocument.vehicle_id_gvo}?tab=datos`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Ver vehículo
                                </a>
                              </p>
                            </Col>
                            <Col xs="12" sm="4">
                              <Button
                                className="btn-flexicar right no-print"
                                onClick={() =>
                                  setLocalDocument({
                                    ...localDocument,
                                    id_vehiculo_GVO: "",
                                    contrato_vinculado: "",
                                    contrato_vinculado_marca: "",
                                    contrato_vinculado_modelo: "",
                                    vehicle_id_gvo: "",
                                  })
                                }
                              >
                                Cambiar coche asociado
                              </Button>
                            </Col>
                          </Row>
                        </ToastBody>
                      </Toast>
                    </div>
                  </>
                ) : null}
                {localDocument?.forma_pago === "3" ? (
                  <>
                    <div className="p-3 my-2 rounded col-12 col-sm-12">
                      <Toast style={{ maxWidth: "100%" }}>
                        <ToastHeader>Cuenta Bancaria</ToastHeader>
                        <ToastBody>
                          <Row>
                            <Col xs="12" sm="8">
                              <Input
                                placeholder={"Cuenta bancaria"}
                                className="form-control ds-input mt-1"
                                type="text"
                                value={localDocument.cuenta}
                                onChange={(e) => {
                                  handleSimpleInput(e, "cuenta");
                                }}
                              />
                            </Col>
                          </Row>
                        </ToastBody>
                      </Toast>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </Row>

          <Row className={styles.centerRow}>
            <Col xs="12" sm="12">
              <Label>{t('observations')}</Label>
              <textarea
                placeholder={t('observations')}
                className="form-control ds-input mt-1"
                type="text"
                value={localDocument.observaciones}
                onChange={(e) => {
                  handleSimpleInput(e, "observaciones");
                }}
              />
            </Col>
          </Row>

          {(CONTRACT_PURCHASE || TYPE_MANAGEMENT) && (
            <Row className={styles.centerRow}>
              <Col xs="12" sm="12">
                <Label>{t('annexed')}</Label>
                <textarea
                  placeholder={t('annexed')}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={localDocument.anexo}
                  onChange={(e) => {
                    handleSimpleInput(e, "anexo");
                  }}
                />
              </Col>
            </Row>
          )}
        </Container>

        <div>
          <Button
            className="btn-flexicar mt-4 mr-5 right no-print"
            onClick={saveUpdate}
          >
            {t('save')}
          </Button>
          <br />
          <div className="m-5" id="template"></div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={close}>
          {t('close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalUpdateContract;
